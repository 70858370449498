<script lang="ts">
    import { defineComponent, computed, defineAsyncComponent } from "vue";
    import { useRoute } from "vue-router";

    export default defineComponent({
        name: "App",
        components: {
            FooterInd: defineAsyncComponent(() => import('./components/FooterInd.vue')), 
            FooterLanding: defineAsyncComponent(() => import('./components/FooterLanding.vue')),
        },
        setup() {
            const route = useRoute();
            const campus = computed(() => route.meta.campus || '');
            const currentNavbar = computed(() =>
                route.path.match(/^\/campus\/[^\/]+/) ? "NavbarLanding" : "NavbarTs"
            );

            const currentFooter = computed(() =>
                route.path.match(/^\/campus\/[^\/]+/) ? "FooterLanding" : "FooterInd"
            );

            return {
                currentNavbar,
                currentFooter,
                campus,
                route
            };
        },
        methods: {
            showBanner() {
                const _hsp = (window as any)._hsp || [];
                _hsp.push(["showBanner"]);
            },
        },
    });
</script>

<template class="flex flex-col no-scroll w-full bg-white">
    <div class="flex flex-col h-full no-scroll justify-center w-full bg-white">
        <transition name="fade">
            <main :key="route.path" style="min-height: 1500px;">
                <router-view v-slot="{ Component }">
                    <keep-alive>
                        <component :is="Component"/>
                    </keep-alive>
                </router-view>
            </main>
        </transition>
        <component :is="currentFooter" />
        <div class="fixed bottom-2 right-2 z-50">
            <a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
                <img src="/pagesImages/wha-icon.png" width="56" height="55" loading="eager" alt="WhatsApp" class="w-[56px]"/>
            </a>
        </div>
        <div class="flex justify-center items-center cursor-pointer fixed bottom-2 left-2 z-50" @click="showBanner" title="Configuración de cookies">
            <img src="./static/img/logo-h-white.png" width="45" height="49" loading="eager" alt="Cookies Humanitas" class="w-[45px] cursor-pointer"/>
        </div>
    </div>
</template>

<style scoped>
    .no-scroll {
        overflow-x: hidden;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease-in-out;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
