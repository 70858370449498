import { createApp } from 'vue'
import './style.css'
import './setPassiveEvents'
import router from './router';
import AppVue from './App.vue'
// import { metaTagPlugin } from "@m-media/vue3-meta-tags";
// import { initializeApp } from "firebase/app";
// import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Carousel from 'primevue/carousel';
import Aura from '@primevue/themes/aura';
import NavbarInd from "@/components/NavbarInd.vue";
import VideoHiL from "@/pages/components/VideoHiL.vue";
import EgresadosHuma from '@/pages/components/oferta/EgresadosHuma.vue';
import CompIndex from '@/pages/components/CompIndex.vue';
import NewSlide from '@/pages/components/newSlide.vue';
import InfoTresImagenes from '@/pages/components/InfoTresImagenes.vue';

const app = createApp(AppVue);
// const Solicitud = 'https://humanitas.edu.mx/assets/sendAPI.php';
const Solicitud = 'https://humanitas.edu.mx/assets/sendAPI.php';
const Solicitud2 = 'https://humanitas.edu.mx/assets/sendAPI2.php';
//const SolicitudBlog = 'https://humanitas.edu.mx/assets/sendAPIBlog.php';
//const SolicitudBlog = 'https://humanitas.edu.mx/assets/sendAPIBlog.php';
//const ConsultasBlog = 'https://humanitas.edu.mx/assets/Consultas.php'
// const Solicitud2 = 'http://localhost/js/scripts/sendAPI2.php';
const colegiaturaLicEjec = 3080;
const colegiaturaLicEsco = 5200;
const colegiaturaMtr = 4400;
const colegiaturaDoc = 0;
// const Solicitud = 'https://universidad.humanitas.edu.mx/assets/sendAPI.php';
// const Solicitud2 = 'https://universidad.humanitas.edu.mx/assets/sendAPI2.php';
// declare const grecaptcha: any;
// // Agrega las claves de reCAPTCHA como variables de entorno
// const repkey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
// const recaptchaSiteKey = repkey;

// // Configura la referencia a la API de reCAPTCHA
// app.config.globalProperties.$recaptcha = {
// 	async execute(action: any) {
// 		return new Promise((resolve) => {
// 			grecaptcha.enterprise.ready(async () => {
// 				const token = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action });
// 				resolve(token);
// 			});
// 		});
// 	},
// };

// Declarar gtag para TypeScript
declare global {
	interface Window {
        gtag: (...args: any[]) => void;
        ttq?: any;
        TiktokAnalyticsObject?: any;
	}
}

  // Inicializar Google Analytics después de cada navegación
router.afterEach((to) => {
	window.gtag('config', 'G-WG56YHP3SR', {
		page_path: to.fullPath,
	});
});

// Función para cargar el script solo una vez
// function loadTikTokPixel() {
//     if (window.ttq) return; // Evita múltiples cargas
    
//     (function(w, d, t) {
//       w.TiktokAnalyticsObject = t;
//       const ttq :any = (w as any)[t] = (w as any)[t] || [];
  
//       const methods = [
//         "page", "track", "identify", "instances", "debug", "on", "off", 
//         "once", "ready", "alias", "group", "enableCookie", "disableCookie"
//       ];
  
//       ttq.setAndDefer = function(obj: any, method: string) {
//         obj[method] = function() {
//           obj.push([method, ...arguments]);
//         };
//       };
  
//       methods.forEach(method => ttq.setAndDefer(ttq, method));
  
//       ttq.instance = function(id: string) {
//         const inst = ttq._i[id] || [];
//         methods.forEach(method => ttq.setAndDefer(inst, method));
//         return inst;
//       };
  
//       ttq.load = function(pixelId: string, options = {}) {
//         const scriptUrl = "https://analytics.tiktok.com/i18n/pixel/events.js";
//         ttq._i = ttq._i || {};
//         ttq._i[pixelId] = [];
//         ttq._i[pixelId]._u = scriptUrl;
//         ttq._t = ttq._t || {};
//         ttq._t[pixelId] = Date.now();
//         ttq._o = ttq._o || {};
//         ttq._o[pixelId] = options;
  
//         const script = d.createElement("script");
//         script.type = "text/javascript";
//         script.async = true;
//         script.src = `${scriptUrl}?sdkid=${pixelId}&lib=${t}`;
  
//         const firstScript = d.getElementsByTagName("script")[0];
//         firstScript.parentNode?.insertBefore(script, firstScript);
//       };
  
//       // Cargar el pixel con el ID específico
//       ttq.load("CI19OR3C77U2H86M3EL0");
//       ttq.page();
  
//     })(window, document, "ttq");
//   }
  
//   // Cargar TikTok Pixel en cada navegación
//   router.afterEach(() => {
//     if (window.ttq) {
//       window.ttq.page();
//     } else {
//       loadTikTokPixel();
//     }
//   });
  

// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
//   authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
//   databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
//   projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
//   storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
//   appId: import.meta.env.VITE_FIREBASE_APP_ID,
//   measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
// };

// initializeApp(firebaseConfig);
// //Configuración de pinia
// const pinia = createPinia()

// app.use(pinia);
app.use(router);
app.component("NavbarInd", NavbarInd);
app.component("VideoHiL", VideoHiL);
app.component("EgresadosHuma", EgresadosHuma);
app.component("InfoTresImagenes", InfoTresImagenes);


app.component("CompIndex", CompIndex);
app.component("NewSlide", NewSlide);
// Configura el plugin metaTagPlugin
// app.use(metaTagPlugin, {
//     defaultName: 'Universidad Humanitas | 45 años de excelencia académica',
// }, router);
router.beforeEach((to, _from, next) => {
    // Obtén el título desde `meta.title` y actualízalo
    const defaultTitle = 'Mi Sitio'; // Título por defecto si no hay `meta.title`
    document.title = to.meta.title || defaultTitle;
    next();
  });
app.component('Carousel', Carousel);
app.use(PrimeVue, {
	theme: {
		preset: Aura
	}
});
app.mount('#app');



// export { Solicitud, Solicitud2, colegiaturaLicEjec, colegiaturaLicEsco, colegiaturaMtr, colegiaturaDoc, SolicitudBlog, ConsultasBlog };
export { Solicitud, Solicitud2, colegiaturaLicEjec, colegiaturaLicEsco, colegiaturaMtr, colegiaturaDoc };


//createApp(index).mount('#app')
