(function () {
    const originalAddEventListener = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
      // Asegúrate de que el evento sea pasivo por defecto
        const newOptions =
            typeof options === "object"
                ? { ...options, passive: true }
                : { passive: true };
        // Llama al addEventListener original con las opciones modificadas
        return originalAddEventListener.call(this, type, listener, newOptions);
    };
})();