<template>
    <section class="flex flex-col mt-4 items-center justify-center mx-auto w-screen">
        <div class="w-full  px-4 mb-4">
            <p class="leading-tight text-gray-500">
                <span class="block text-[28px]">El mejor momento es ahora...</span>
                <span class="sr-only whitespace-nowrap">&nbsp;</span>
                <span class="block text-[42px] font-serif text-hgold font-medium">Humanitas en la vida</span>
            </p>
        </div>
        <div class=" w-full text-center flex relative justify-center carousel-container items-center mb-12">
            <Carousel :value="carouselItems" ref="carouselRef" :numVisible="3" :numScroll="1" :responsiveOptions="responsiveOptions" :showIndicators="false" circular :autoplayInterval="3800" class="lg:max-w-[1200px] md:max-w-[750px] max-w-[400px] custom-carousel text-center flex relative justify-center">
                <template #item="slotProps">
                    <div class="border border-gray-300 rounded m-4 p-4 flex flex-col items-center text-center ">
                        <img :src="slotProps.data.image" :alt="slotProps.data.nombre" class="w-[120px] h-[120px] my-8 rounded-none" loading="lazy" />
                        <p class="text-lg  text-gray-700">{{ slotProps.data.nombre }}</p>
                        <p class="text-lg mb-4 text-gray-700">{{ slotProps.data.campus }}</p>
                        <p class="mb-4 h-[113px] gx:mx-4 text-lg leading-tight">{{ slotProps.data.descript }}</p>
                        <a :href="slotProps.data.link" class="text-hgold text-lg  mb-12">Leer más...</a>
                    </div>
                </template>
            </Carousel>
        </div>
    </section>
</template>

<script setup>
    import { onMounted, onUnmounted, ref } from "vue";
    const carouselItems = ref([
        {
            image: "/pagesImages/t1-nv.webp",
            nombre: "ANA MARÍA",
            campus: "Campus Tijuana",
            descript: '"Me llamó mucho la atención el Código Humanitas, tanto, que me alentó a inscribirme a la Licenciatura en psicología."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-ana-maria-reynoso-anorga-la-psicologia-y-el-psicoanalisis-historicamente-han-estado-ligados-al-estudio-de-la-motivacion/",
        },
        {
            image: "/pagesImages/t3-nv.webp",
            nombre: "JAVIER RAMÍREZ",
            campus: "Campus Del Valle",
            descript: '"Una institución que inspira no sólo al estudio y generación de conocimiento, sino también a cultivar una filosofía de vida."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-javier-ramirez-fragoso-universidad-humanitas-la-semilla-que-dio-genesis-a-la-motivacion-de-estudiar/",
        },
        {
            image: "/pagesImages/t2-nv.webp",
            nombre: "ERIKA ANDRADE",
            campus: "Campus Del Valle",
            descript: '"A diferencia de otras escuelas, Humanitas tiene un enfoque objetivo; con instalaciones que inspiran al estudio."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-erika-andrade-compromiso-e-innovacion-para-una-verdadera-transformacion/",
        },
        {
            image: "/pagesImages/t5-nv.webp",
            nombre: "ISABEL CECILIA",
            campus: "Campus Cancún",
            descript: '"Siempre conté con profesores dispuestos a compartir sus conocimientos y compañeros expertos en distintas áreas."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-isabel-cecilia-gonzalez-glennie-liderazgo-para-mostrar-a-los-demas-el-camino-de-la-excelencia/",
        },
        {
            image: "/pagesImages/t6-nv.webp",
            nombre: "JESÚS FIDEL",
            campus: "Campus Del Valle",
            descript: '"Tiene la flexibilidad para quienes desarrollamos un trabajo profesional con jornadas laborales completas."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-jesus-fidel-hernandez-rodriguez-amor-a-todo-aquello-que-realicemos/",
        },
        {
            image: "/pagesImages/t4-nv.webp",
            nombre: "BIBIANA JIMÉNEZ",
            campus: "Campus Tijuana",
            descript: '"Aprendí que cuando algo sucede tienes varias opciones: dejar que te marque, que te destruya o que te fortalezca."',
            link: "https://capitel.humanitas.edu.mx/entrevista-a-bibiana-jimenez-ramirez-es-necesario-estar-actualizada-investigar-y-prepararse/",
        },
    ]);

    const responsiveOptions = ref([
        { breakpoint: "1199px", numVisible: 3, numScroll: 1 },
        { breakpoint: "768px", numVisible: 2, numScroll: 1 },
        { breakpoint: "575px", numVisible: 1, numScroll: 1 },
    ]);

    const carouselRef = ref(null);

    const events = [
        { type: 'touchmove', handler: handleTouchMove },
        { type: 'wheel', handler: handleWheel },
    ];

    onMounted(() => {
        // @ts-ignore
        const carouselElement = carouselRef.value?.$el; // Accede al elemento raíz del carrusel
        if (carouselElement) {
            // Agregar listeners pasivos para cada evento
            events.forEach(({ type, handler }) => {
            carouselElement.addEventListener(type, handler, { passive: true });
            });
        }
    });

    onUnmounted(() => {
        // @ts-ignore
        const carouselElement = carouselRef.value?.$el;
        if (carouselElement) {
            // Eliminar listeners al desmontar
            events.forEach(({ type, handler }) => {
            carouselElement.removeEventListener(type, handler);
            });
        }
    });

    // Handlers para eventos específicos
    function handleTouchMove() {
        
    }

    function handleWheel() {
    }
</script>

<style scoped>
    .carousel-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>