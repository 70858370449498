<script lang="ts">
    import { defineComponent } from "vue";

export default defineComponent({
        props: {
            types: {
                type: String,
                required: false,
                default: "Default"
            }
        },
        data() {
            return {
                showImage: true,
                videoUrl: "https://www.youtube.com/embed/N8nkKnQvWXI?si=RbWK4b2pm_B2zWni",
                videoThumbnail: "../pagesImages/play-icon.png",
                typesOptions: {
                    "Default": {
                        title: "Egresados", complement: "Humanitas", text: "Son personas decididas a triunfar, con capacidades superiores para superar cualquier obstáculo, por difícil que parezca. Demuestran que el esfuerzo, la dedicación y la perseverancia son el camino hacia el éxito.", boton: true, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                    "Home": {
                        title: "Egresados", complement: "Humanitas", text: "Son personas decididas a triunfar, con capacidades superiores para superar cualquier obstáculo, por difícil que parezca. Demuestran que el esfuerzo, la dedicación y la perseverancia son el camino hacia el éxito.", boton: true, video: true, background: "/pagesImages/banner-egresados-humanitas.webp"
                    },
                    "Licenciaturas": {
                        title: "Egresados de licenciaturas", complement: "en Humanitas", text: "Son personas decididas a triunfar, con capacidades superiores para superar cualquier obstáculo, por difícil que parezca. Demuestran que el esfuerzo, la dedicación y la perseverancia son el camino hacia el éxito.<br><br>En Humanitas, estamos profundamente orgullosos de sus logros: hoy egresas como un profesional preparado para enfrentar los retos del futuro y dejar huella en el mundo.", boton: false, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                    "Nosotros": {
                        title: "Egresados", complement: "Humanitas", text: "Son personas comprometidas con su superación. Demuestran que el esfuerzo, la dedicación y la perseverancia son las claves para alcanzar el éxito.", boton: false, video: false, background: "/pagesImages/banner-egresados-humanitas.webp"
                    },
                    "Continua": {
                        title: "Egresados de", complement: "educación continua Humanitas", text: "Los egresados de los programas educativos de nuestro centro de educación continua son personas que demuestran  la capacidad de transformar los desafíos en oportunidades de crecimiento.<br><br>Has demostrado que, con determinación y esfuerzo, no hay meta que no se pueda alcanzar. Por ello, estamos profundamente orgullosos de tu logro al culminar este curso en Universidad Humanitas.", boton: false, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                    "Escolarizado": {
                        title: "Egresados", complement: "Humanitas", text: "El verdadero éxito no es solo alcanzar una meta, sino superar cada desafío en el camino. Los egresados de la Universidad Humanitas son la prueba de que la determinación y el esfuerzo, abren puertas a grandes oportunidades.<br><br> Detrás de este logro, hay dedicación, perseverancia y un futuro brillante. El comienzo de todo lo que nuestros egresados pueden lograr.", boton: false, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                    "Ejecutivo": {
                        title: "Egresados", complement: "Humanitas", text: "El verdadero éxito no es solo alcanzar una meta, sino superar cada desafío en el camino. Los egresados de Universidad Humanitas son la prueba de que la determinación y el esfuerzo abren las puertas a las grandes oportunidades. Esto es sólo el comienzo de todo lo que pueden lograr. ", boton: false, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                    "Virtual": {
                        title: "Egresados", complement: "Humanitas", text: "Son personas decididas a triunfar, con capacidades superiores para superar cualquier obstáculo, por difícil que parezca. Demuestran que el esfuerzo, la dedicación y la perseverancia son el camino hacia el éxito.<br><br>En Humanitas, estamos profundamente orgullosos de sus logros: hoy egresas como un profesional preparado para enfrentar los retos del futuro y dejar huella en el mundo.", boton: false, video: true, background: "/pagesImages/banner-egresados-BLANCO.webp"
                    },
                },   
            };
        },
        computed: {
            selectedType():any {
                //@ts-ignore
                return this.typesOptions[this.types] || this.typesOptions["Default"];
            }
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
        },
    });
</script>

<template>
    <section class="flex justify-center items-center w-screen h-full lg:h-[500px] bg-cover lg:bg-[center] bg-[left] bg-no-repeat" loading="lazy" style="background-image: url(/pagesImages/banner-egresados-humanitas.webp); " :style="{ backgroundImage: `url(${selectedType.background})` }">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:w-[50%] lg:items-start  w-full lg:my-36 my-12 text-justify md:px-4 px-12">
                <h2 class="text-hwine lg:text-left text-center leading-none mb-2 font-serif">
                    <span class="block text-[28px]">{{ selectedType.title }}</span>
                    <span class="sr-only whitespace-nowrap">&nbsp;</span>
                    <span class="block text-[42px]">{{ selectedType.complement }}</span>
                </h2>
                <p class="text-gray-800 text-base lg:w-[90%]" v-html="selectedType.text"></p>
                <br>
                <router-link v-if="selectedType.boton" to="/contacto">
                    <div class="flex items-center justify-center w-[140px] h-[35px] hover:bg-hwine bg-hgold text-white text-lg transition duration-300 ease-in-out">
                        <span>Inscríbete</span>
                    </div>
                </router-link>
            </div>
            <div class="flex flex-col items-center justify-center lg:w-[30%] w-full my-16 ">
                <div class="flex justify-center w-full h-full items-center " v-if="selectedType.video">
                    <VideoHiL :thumbnailUrl="videoThumbnail" :videoUrl="videoUrl" class="w-[100%]  md:px-16 lg:px-0"/>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>