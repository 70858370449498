import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
//import mainRoutes from './router/mainRoutes.ts';
//import { getAuth } from 'firebase/auth';
declare module 'vue-router' {
    interface RouteMeta {
      title?: string; // Agrega el tipo de la meta propiedad `title`
    }
}

const routes: RouteRecordRaw[] = [
		{ path: '/', component: () => import('@/pages/HomePage.vue'), name: 'Universidad Humanitas',
			meta: {
				title: "Universidad Humanitas | 46 años de excelencia académica",
				description: "Impulsamos tu futuro: Licenciaturas, posgrados y educación continua. Horarios y costos accesibles. Modalidades presencial, ejecutiva y virtual. Logra la mejor versión de ti con nosotros ¡Inscríbete!",
				image: "https://cloudinary-assets.dostuffmedia.com/res/dostuff-media/image/upload/venue-471080/1635014689.jpg",
				metaTags: {
					"og:title": "Universidad Humanitas - 46 años de excelencia académica",
					"og:description": "Impulsamos tu futuro: Licenciaturas, posgrados y educación continua. Horarios y costos accesibles. Modalidades presencial, ejecutiva y virtual. Logra la mejor versión de ti con nosotros ¡Inscríbete!",
					"og:image": "https://cloudinary-assets.dostuffmedia.com/res/dostuff-media/image/upload/venue-471080/1635014689.jpg",
				},
				//requireAuth: false
			},
		},
		{ path: '/nosotros', component: () => import('@/pages/NosotrosPage.vue'), name: 'Nosotros',
			meta: {
				title: "Universidad Humanitas | Nosotros",
				//requireAuth: false
			},
		},
		{ path: '/responsabilidad', component: () => import('@/pages/ResponPage.vue'), name: 'Responsabilidad',
			meta: {
				title: "Universidad Humanitas | Responsabilidad",
				//requireAuth: false
			} 
		},
		{ path: '/ejecutivo', component: () => import('@/pages/ModeloEjecPage.vue'), name: 'Modelo Ejecutivo',
			meta: {
				title: "Universidad Humanitas | Modelo ejecutivo",
				//requireAuth: false
			} 
		},
		{ path: '/escolarizado', component: () => import('@/pages/ModeloEscoPage.vue'), name: 'Modelo Escolarizado',
			meta: {
				title: "Universidad Humanitas | Modelo escolarizado",
				//requireAuth: false
			} 
		},
        // {
        //     path: '/virtual', component: () => import('@/pages/ModeloVirtPage.vue'), name: 'Modelo Virtual', 
        //         meta: {
        //             title: "Universidad Humanitas | Modelo Virtual",
        //             //requireAuth: false
        //         } 
        // },
		{ path: '/licenciaturas', component: () => import('@/pages/OfertaLicPage.vue'), name: 'Licenciaturas',
			meta: {
				title: "Universidad Humanitas | Licenciaturas",
				//requireAuth: false
			} 
		},
		//{ path: '/licenciaturas-virtual', component: OfertaLicVirtPage, name: 'Licenciaturas Virtual' },
		{ path: '/licenciatura-en-administracion', component: () => import('@/pages/licenciatura/LicAdminPage.vue'), name: 'Licenciatura en Administración',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Administración",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-arquitectura', component: () => import('@/pages/licenciatura/LicArquiPage.vue'), name: 'Licenciatura en Arquitectura',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Arquitectura",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-arte-y-teatro', component: () => import('@/pages/licenciatura/LicArtePage.vue'), name: 'Licenciatura en Arte y Teatro',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Arte y Teatro",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-ciencias-politicas', component: () => import('@/pages/licenciatura/LicCienciasPage.vue'), name: 'Licenciatura en Ciencias Políticas y Administración Pública',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Ciencias Políticas y Administración Pública",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-contabilidad', component: () => import('@/pages/licenciatura/LicContaPage.vue'), name: 'Licenciatura en Contabilidad',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Contabilidad",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-derecho', component: () => import('@/pages/licenciatura/LicDerechoPage.vue'), name: 'Licenciatura en Derecho',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Derecho",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-diseno-grafico', component: () => import('@/pages/licenciatura/LicDisenoPage.vue'), name: 'Licenciatura en Diseño Gráfico',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Diseño Gráfico",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-educacion', component: () => import('@/pages/licenciatura/LicEducaPage.vue'), name: 'Licenciatura en Educación',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Educación",
				//requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-psicologia', component: () => import('@/pages/licenciatura/LicPsicPage.vue'), name: 'Licenciatura en Psicología',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Psicología",
				//requireAuth: false
			} 
		},
		{ path: '/requisitos-licenciatura', component: () => import('@/pages/licenciatura/LicRequisitosPage.vue'), name: 'Requisitos de Inscripción Licenciatura',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Licenciatura",
				//requireAuth: false
			} 
		},
		{ path: '/maestrias', component: () => import('@/pages/OfertaMtrPage.vue'), name: 'Maestrías',
			meta: {
				title: "Universidad Humanitas | Maestrías",
				//requireAuth: false
			} 
		},
		//{ path: '/maestrias-virtual', component: OfertaMtrVirtPage, name: 'Maestrías Virtual' },
		{ path: '/maestria-en-alta-direccion', component: () => import('@/pages/maestria/MtrAltaDirPage.vue'), name: 'Maestría en Alta Dirección Corporativa',
			meta: {
				title: "Universidad Humanitas | Maestría en Alta Dirección Corporativa",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-contabilidad-e-impuestos', component: () => import('@/pages/maestria/MtrContaImpPage.vue'), name: 'Maestría en Contabilidad con Especialidad en Impuestos',
			meta: {
				title: "Universidad Humanitas | Maestría en Contabilidad con especialidad en impuestos",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-corporativo', component: () => import('@/pages/maestria/MtrDerechoEmpPage.vue'), name: 'Maestría en Derecho Empresarial Corporativo',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Empresarial Corporativo",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-fiscal', component: () => import('@/pages/maestria/MtrDerechoFiscalPage.vue'), name: 'Maestría en Derecho Fiscal',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Fiscal",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-penal', component: () => import('@/pages/maestria/MtrDerechoPenalPage.vue'), name: 'Maestría en Derecho Penal',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Penal",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-educacion', component: () => import('@/pages/maestria/MtrEducaPage.vue'), name: 'Maestría en Educación',
			meta: {
				title: "Universidad Humanitas | Maestría en Educación",
				//requireAuth: false
			}
		},
		{ path: '/maestria-en-justicia-penal', component: () => import('@/pages/maestria/MtrJusticiaPenalPage.vue'), name: 'Maestría en Justicia Penal y Seguridad Pública',
			meta: {
				title: "Universidad Humanitas | Maestría en Justicia Penal y Seguridad Pública",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-mercadotecnia', component: () => import('@/pages/maestria/MtrMercadotecniaPage.vue'), name: 'Maestría en Mercadotecnia',
			meta: {
				title: "Universidad Humanitas | Maestría en Mercadotecnia",
				//requireAuth: false
			} 
		},
		{ path: '/maestria-en-psicoterapia', component: () => import('@/pages/maestria/MtrPsicoterapiaPage.vue'), name: 'Maestría en Psicoterapia con un Enfoque Psicoanalítico',
			meta: {
				title: "Universidad Humanitas | Maestría en Psicoterapia con un Enfoque Psicoanalítico",
				//requireAuth: false
			} 
		},
		{ path: '/requisitos-maestria', component: () => import('@/pages/maestria/MtrRequisitosPage.vue'), name: 'Requisitos de Inscripción Maestría',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Maestría",
				//requireAuth: false
			} 
		},
		{ path: '/doctorados', component: () => import('@/pages/OfertaDocPage.vue'), name: 'Doctorados',
			meta: {
				title: "Universidad Humanitas | Doctorados",
				//requireAuth: false
			} 
		},
		{ path: '/doctorado-en-alta-direccion', component: () => import('@/pages/doctorado/DocAltaDirPage.vue'), name: 'Doctorado en Alta Dirección',
			meta: {
				title: "Universidad Humanitas | Doctorado en Alta Dirección",
				//requireAuth: false
			}
		},
		{ path: '/doctorado-en-derecho', component: () => import('@/pages/doctorado/DocDerechoPage.vue'), name: 'Doctorado en Derecho',
			meta: {
				title: "Universidad Humanitas | Doctorado en Derecho",
				//requireAuth: false
			} 
		},
		{ path: '/doctorado-en-educacion', component: () => import('@/pages/doctorado/DocEducaPage.vue'), name: 'Doctorado en Educación',
			meta: {
				title: "Universidad Humanitas | Doctorado en Educación",
				//requireAuth: false
			} 
		},
		{ path: '/requisitos-doctorado', component: () => import('@/pages/doctorado/DocRequisitosPage.vue'), name: 'Requisitos de Inscripción Doctorado',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Doctorado",
				//requireAuth: false
			} 
		},
		{ path: '/educacion-continua', component: () => import('@/pages/diplomado/EducacionContinuaPage.vue'), name: 'Educación Continua',
			meta: {
				title: "Universidad Humanitas | Educación Continua",
				//requireAuth: false
			} 
		},
		{ path: '/english-kingdom', component: () => import('@/pages/idioma/EnglishPage.vue'), name: 'English Kingdom',
			meta: {
				title: "Universidad Humanitas | English Kingdom",
				//requireAuth: false
			} 
		},
		{ path: '/usuarios', component: () => import('@/pages/AccesoUsrPage.vue'), name: 'Usuarios',
			meta: {
				title: "Universidad Humanitas | Usuarios",
				//requireAuth: false
			} 
		},
		{ path: '/aviso-de-privacidad', component: () => import('@/pages/AvisoPrivPage.vue'), name: 'Aviso de Privacidad',
			meta: {
				title: "Universidad Humanitas | Aviso de Privacidad",
				//requireAuth: false
			} 
		},
		{ path: '/bolsa-de-trabajo', component: () => import('@/pages/BolsaTrabajoPage.vue'), name: 'Bolsa de Trabajo',
			meta: {
				title: "Universidad Humanitas | Bolsa de Trabajo",
				//requireAuth: false
			} 
		},
		{ path: '/campus', component: () => import('@/pages/CampusPage.vue'), name: 'Campus',
			meta: {
				title: "Universidad Humanitas | Campus",
				//requireAuth: false
			} 
		},
		{ 
			path: '/campus/cancun',
			component: () => import('@/pages/landing/CampusCancun.vue'),
			name: 'CampusCancun',
			meta: {
				title: "Cancún | Humanitas",
				campus: "Campus Cancún",
                nombre: "Campus Magno Cancún",
                hideNavbar: true,
			},
			props: {
				banner: "cancun",
			},
		},
		{ 
			path: '/campus/chihuahua',
			component: () => import('@/pages/landing/CampusChihuahua.vue'),
			name: 'CampusChihuahua',
			meta: {
				title: "Chihuahua | Humanitas",
				campus: "Campus Chihuahua",
                nombre: "Campus Magno Chihuahua",
                hideNavbar: true,
			},
			props: {
				banner: "chihuahua",
			},
		},
		{ 
			path: '/campus/chihuahua',
			component: () => import('@/pages/landing/CampusChihuahua.vue'),
			name: 'CampusChihuahua',
			meta: {
				title: "Chihuahua | Humanitas",
				campus: "Campus Chihuahua",
                nombre: "Campus Magno Chihuahua",
                hideNavbar: true,
			},
			props: {
				banner: "chihuahua",
			},
		},
		{ 
			path: '/campus/del-valle',
			component: () => import('@/pages/landing/CampusDelValle.vue'),
			name: 'CampusDelValle',
			meta: {
				title: "Del Valle | Humanitas",
				campus: "Campus Del Valle",
                nombre: "Campus Magno Del Valle",
                hideNavbar: true,
			},
			props: {
				banner: "del-valle",
			},
		},
		{ 
			path: '/campus/guadalajara',
			component: () => import('@/pages/landing/CampusGuadalajara.vue'),
			name: 'CampusGuadalajara',
			meta: {
				title: "Guadalajara | Humanitas",
				campus: "Campus Guadalajara",
                nombre: "Centro de Impulso Empresarial Guadalajara",
                hideNavbar: true,
			},
			props: {
				banner: "guadalajara",
			},
		},
		{ 
			path: '/campus/merida',
			component: () => import('@/pages/landing/CampusMerida.vue'),
			name: 'CampusMerida',
			meta: {
				title: "Mérida | Humanitas",
				campus: "Campus Mérida",
                nombre: "Campus Magno Mérida",
                hideNavbar: true,
			},
			props: {
				banner: "merida",
			},
		},
		{ 
			path: '/campus/presa-madin',
			component: () => import('@/pages/landing/CampusPresaMadin.vue'),
			name: 'CampusPresaMadin',
			meta: {
				title: "Presa Madín | Humanitas",
				campus: "Campus Presa Madín",
                nombre: "Campus Magno Presa Madín",
                hideNavbar: true,
			},
			props: {
				banner: "presa-madin",
			},
		},
		{ 
			path: '/campus/queretaro',
			component: () => import('@/pages/landing/CampusQueretaro.vue'),
			name: 'CampusQueretaro',
			meta: {
				title: "Querétaro | Humanitas",
				campus: "Campus Querétaro",
                nombre: "Campus Magno Querétaro",
                hideNavbar: true,
			},
			props: {
				banner: "queretaro",
			},
		},
		{ 
			path: '/campus/los-reyes',
			component: () => import('@/pages/landing/CampusReyes.vue'),
			name: 'CampusReyes',
			meta: {
				title: "Los Reyes | Humanitas",
				campus: "Campus Los Reyes",
                nombre: "Campus Magno Los Reyes",
                hideNavbar: true,
			},
			props: {
				banner: "los-reyes",
			},
		},
		{ 
			path: '/campus/santa-fe',
			component: () => import('@/pages/landing/CampusSantaFe.vue'),
			name: 'CampusSantaFe',
			meta: {
				title: "Santa Fe | Humanitas",
				campus: "Campus Santa Fe",
                nombre: "Centro de Impulso Empresarial Santa Fe",
                hideNavbar: true,
			},
			props: {
				banner: "santa-fe",
			},
		},
		{ 
			path: '/campus/tijuana',
			component: () => import('@/pages/landing/CampusTijuana.vue'),
			name: 'CampusTijuana',
			meta: {
				title: "Tijuana | Humanitas",
				campus: "Campus Tijuana",
                nombre: "Campus Magno Tijuana",
                hideNavbar: true,
			},
			props: {
				banner: "tijuana",
			},
		},
		{ path: '/contacto', component: () => import('@/pages/ContactoPage.vue'), name: 'Contacto',
			meta: {
				title: "Universidad Humanitas | Contacto",
				//requireAuth: false
			}
		},
		{ path: '/despierta', component: () => import('@/pages/DespiertaPage.vue'), name: 'Despierta',
			meta: {
				title: "Universidad Humanitas | Despierta",
				//requireAuth: false
			}
		},
		{ path: '/:pathMatch(.*)*', redirect: () => ({ name: 'Universidad Humanitas' }) },

		//...mainRoutes
	]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

router.beforeEach((to, _, next) => {
	// Lista de parámetros prohibidos
	const forbiddenParams = ['__hstc=', '__hssc=', '__hsfp='];
	// Comprobar si la ruta de destino contiene algún parámetro prohibido
	const hasForbiddenParams = forbiddenParams.some(param => to.query.hasOwnProperty(param));
	// Comprobar si la ruta tiene barra diagonal al final
	const hasTrailingSlash = to.path !== '/' && to.path.endsWith('/');
	// Si hay parámetros prohibidos
	if (hasForbiddenParams) {
	  	// Redirigir a la misma ruta sin parámetros
		const cleanPath = to.path;
		next({ path: cleanPath, query: {} });
	} 
	// Si hay barra diagonal al final
	else if (hasTrailingSlash) {
		// Redirigir a la ruta sin la barra diagonal al final
		next({ path: to.path.slice(0, -1), query: to.query });
	} 
	// Si no hay parámetros prohibidos ni barra al final, continuar
	else {
		next();
	}
});

// router.beforeEach((to, _, next) => {
// 	const requiresAuth = to.matched.some(record => record.meta.requireAuth);
// 	const auth = getAuth();
// 	const isAuthenticated = !!auth.currentUser;

// 	if (requiresAuth && !isAuthenticated) {
// 		next('/blog/login');
// 	} else {
// 		next();
// 	}
// });


export default router;
